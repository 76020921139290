import React from "react";

function Success({word}) { 
    return(
        <div>

        <video width="960" height="540" autoPlay muted>
            <source src={"video/" + word + ".mp4"} type="video/mp4" />

            Your browser does not support the video tag.
        </video>

        <h1 className="word text-center">{word.toUpperCase()}</h1>

        <h2 className="info text-center">TRYKK ENTER</h2>

    </div>
    );
}

export default Success;