import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/header/Header.tsx';
import Container from './components/container/Container.tsx';

function App() {

  const words = [ 'katt', 'is', 'ku', 'gris', 'ape', 'mus', 'øye', 'bok' ];

  const [shuffledWords, shuffleWords] = useState(words.sort(() => Math.random() - 0.5));

  // const shuffledWords = words.sort(() => Math.random() - 0.5);

  let wordsObj = shuffledWords.map((word, i) => ({
    id: i,
    word: word,
    img: word + '.jpg',
    points: word.length
  }));

  useEffect(() => {
    wordsObj = shuffledWords.map((word, i) => ({
      id: i,
      word: word,
      img: word + '.jpg',
      points: word.length
    }));
  }, [shuffledWords]);



  return (
    <div className="App">
      <Header />
      <Container words={wordsObj} />
    </div>
  );
}

export default App;
