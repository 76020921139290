import React from "react";

function Word({word, slicer, number}) {

    return(
    <   div>
            <img src={"img/" + word + ".png"} alt={"Bilde av " + word} />
            <h1 className="word text-center">{word.toUpperCase().slice(0, slicer)} {word.replace(word, "_ ".repeat(number) ) }</h1>
        </div>
    );
}

export default Word;