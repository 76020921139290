import React, { useEffect, useState } from "react";
import "./Container.css";
import Success from "../success/Success.tsx";
import Word from "../word/Word.tsx";
import a from "../../audio/A.mp3";
import b from "../../audio/B.mp3";
import c from "../../audio/C.mp3";
import d from "../../audio/D.mp3";
import e from "../../audio/E.mp3";
import f from "../../audio/F.mp3";
import g from "../../audio/G.mp3";
import h from "../../audio/H.mp3";
import i from "../../audio/I.mp3";
import j from "../../audio/J.mp3";
import k from "../../audio/K.mp3";
import l from "../../audio/L.mp3";
import m from "../../audio/M.mp3";
import n from "../../audio/N.mp3";
import o from "../../audio/O.mp3";
import p from "../../audio/P.mp3";
import q from "../../audio/Q.mp3";
import r from "../../audio/R.mp3";
import s from "../../audio/S.mp3";
import t from "../../audio/T.mp3";
import u from "../../audio/U.mp3";
import v from "../../audio/V.mp3";
import w from "../../audio/W.mp3";
import x from "../../audio/X.mp3";
import y from "../../audio/Y.mp3";
import z from "../../audio/Z.mp3";
import æ from "../../audio/AE.mp3";
import ø from "../../audio/OO.mp3";
import å from "../../audio/AA.mp3";

function Container({words}) {

    const [counter, setCounter] = useState(0);
    
    const [word, setWord] = useState(words[counter]?.word);

    const [letter, setLetter] = useState(words[counter]?.word[0]);

    const [score, setScore] = useState(0);

    const [ number, setNumber] = useState(words[counter]?.points);

    const [ slicer, setSlicer] = useState(number * (- 1));

    const [audioSrc, setAudioSrc] = useState<any>();

    const [key, setKey] = useState<KeyboardEvent>();

    const sound = new Audio(audioSrc);

    const letterSounds = [
        {letter: 'a', sound: a},
        {letter: 'b', sound: b},
        {letter: 'c', sound: c},
        {letter: 'd', sound: d},
        {letter: 'e', sound: e},
        {letter: 'f', sound: f},
        {letter: 'g', sound: g},
        {letter: 'h', sound: h},
        {letter: 'i', sound: i},
        {letter: 'j', sound: j},
        {letter: 'k', sound: k},
        {letter: 'l', sound: l},
        {letter: 'm', sound: m},
        {letter: 'n', sound: n},
        {letter: 'o', sound: o},
        {letter: 'p', sound: p},
        {letter: 'q', sound: q},
        {letter: 'r', sound: r},
        {letter: 's', sound: s},
        {letter: 't', sound: t},
        {letter: 'u', sound: u},
        {letter: 'v', sound: v},
        {letter: 'w', sound: w},
        {letter: 'x', sound: x},
        {letter: 'y', sound: y},
        {letter: 'z', sound: z},
        {letter: 'æ', sound: æ},
        {letter: 'ø', sound: ø},
        {letter: 'å', sound: å},
      ];

    window.onkeydown = (ev: KeyboardEvent): any => {
        setKey(ev);
        var letterObj = letterSounds.find((letterSound) => letterSound.letter == ev.key.toLowerCase());
        if( letterObj != undefined){ 
            setAudioSrc(letterObj.sound);
            sound.loop = false;
            sound.currentTime = 0;
        }
    };

    useEffect(() =>{
        setWord( words[counter]?.word);   
    }, [counter] );


    useEffect(() => {
        setNumber(words[counter]?.points - score);
    }, [score]);

    useEffect(() => {
        if( !isNaN(number)){    
            setSlicer(number * (- 1));
            setLetter(word[words[counter]?.points - number]); 
        }
    }, [number]);

    useEffect(() => {
        if( words[counter]?.word == undefined ){
            if(key?.key == 'Enter'){
                setCounter(0);
                setScore(0);
                setWord(words[0]?.word);
                setNumber(words[0]?.points);
            }
        } else {
            if( score != words[counter]?.points){
                if(letter == key?.key.toLowerCase()){
                    setScore(score+1);
                    sound.play();
                }
            } else {
                if(key?.key == 'Enter'){
                    setCounter(counter+1);
                    setScore(0);
                }
            }
        }
    }, [key]);


    return (
        <div className="container pt-3">
            {(words[counter]?.word == undefined) ? <h1 className="word text-center p-3">FERDIG! <br />TRYKK ENTER FOR Å SPILLE IGJEN.</h1> : 
            (score == words[counter]?.points) ? <Success word={word} /> : <Word word={word} slicer={slicer} number={number} /> }
        </div>    
    );
           
 }
   
 export default Container;